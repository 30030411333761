import ExtendableError from 'ts-error'

export default class ApiError extends ExtendableError {
	errorCode: string
	payload: object
	status: number

	constructor({
		message,
		errorCode,
		status = 400,
		additionalPayload = {},
	}: {
		message: string
		errorCode: string
		status?: number
		additionalPayload?: object
	}) {
		super(message)
		this.errorCode = errorCode
		this.status = status
		this.payload = {errorCode, message, ...additionalPayload}
	}
}
