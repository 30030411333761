/** @jsx jsx */
import {jsx, css} from '@emotion/core'
import {useObserver} from 'mobx-react-lite'
import {Typography, Icon} from 'antd'
import {animated} from 'react-spring'
import LightControl from './LightControl'
import FanControl from './FanControl'
import {RoomData} from '../../../../api/types/rooms'
import {useAppState} from '../../../state'
import Container from '../../Container'
import CurrentTemperature from './CurrentTemperatureView'
import HeatingControl from './HeatingControl'
import CoolingControl from './CoolingControl'

const root = css`
	position: fixed;
	z-index: 3;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(46, 46, 46, 0.24);
	overflow-y: scroll;

	display: flex;
	flex-direction: column;

	/* Hide scrollbar */
	scrollbar-width: none;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		width: 0px;
		background: transparent;
	}
`

const whiteArea = css`
	margin: 0 auto;
	max-width: 35rem;
	flex-shrink: 0;
	background: white;
	width: 100%;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
`

const escapeDiv = css`
	flex: 1;
	width: 100%;
`

const titleRow = css`
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	display: flex;
	align-items: center;
	background: #f8f9f9;
	padding-bottom: 0;
	padding-top: 0;
`

const title = css`
	flex: 1;
	&& {
		margin: 1.75rem 0;
		font-size: 1.2rem;
		line-height: 1;
	}
`

const close = css`
	font-size: 1rem;
	margin: -1rem;
	padding: 1rem;
`

const controlsContainer = css`
	background: white;
	> * {
		margin: 1.7rem 0;
	}
`

type Props = {
	room?: RoomData
	onHideClick: () => void
	animationStyles: any
}
function RoomControl({room, onHideClick, animationStyles}: Props) {
	const {home} = useAppState()

	return useObserver(() => {
		if (!room) return null
		const temperature = home.getRoomTemperature(room.id)
		return (
			<animated.div
				className="test"
				style={{opacity: animationStyles.opacity}}
				css={root}
			>
				<div css={escapeDiv} onClick={onHideClick} />
				<animated.div
					style={{transform: animationStyles.transform}}
					css={whiteArea}
				>
					<Container css={titleRow}>
						<Typography.Title level={3} css={title}>
							{room.displayName}
						</Typography.Title>
						<Icon onClick={onHideClick} css={close} type="close" />
					</Container>
					<Container css={controlsContainer}>
						{room.hasLight && (
							<LightControl light={home.getLightsInRoom(room.id)} />
						)}
						{room.hasFan && (
							<FanControl fan={home.getFanInRoom(room.id)} />
						)}
						{temperature && <CurrentTemperature temperature={temperature} />}
						{room.hasHeating && (
							<HeatingControl heating={home.getHeatingInRoom(room.id)} />
						)}
						{room.hasCooling && (
							<CoolingControl cooling={home.getCoolingInRoom(room.id)} />
						)}
					</Container>
				</animated.div>
			</animated.div>
		)
	})
}

export default RoomControl
