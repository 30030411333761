import 'prevent-pull-refresh'
import './app/utils/setupSentry'
import 'antd/dist/antd.css'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'
import * as serviceWorker from './serviceWorker'

const {error, warn} = console
// eslint-disable-next-line no-console
console.error = (...args: any[]) => {
	if (Object.keys(args[0]).indexOf('errors') !== -1) {
		return
	}
	error(...args)
}

// eslint-disable-next-line no-console
console.warn = (...args: any[]) => {
	if (typeof args[0] === 'string' && args[0].startsWith('async-validator:')) {
		return
	}

	warn(...args)
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// Supress validation logs in antd form
