import {SocketUpdateData} from '../../api/socketApi'
import HomeStore from '../state/HomeStore'

export default function processSocketInput(
	data: {
		type: 'set' | 'update'
		data: SocketUpdateData
	},
	homeStore: HomeStore
) {
	switch (data.type) {
		case 'set':
			homeStore.overrideAll(data.data)
			break
		case 'update':
			homeStore.update(data.data)
			break
		default:
			console.warn('Received unexpected data type from socket', data)
	}
}
