import React from 'react'
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch,
} from 'react-router-dom'
import LoginScreen from './LoginScreen'
import InsideScreen from './InsideScreen'
import AuthSensitiveRoute from '../utils/AuthSensitiveRoute'

function AppRouter() {
	return (
		<Router>
			<Switch>
				<AuthSensitiveRoute mode="onlyNotLogged" path="/login">
					<LoginScreen />
				</AuthSensitiveRoute>
				<AuthSensitiveRoute mode="onlyLogged" path="/">
					<InsideScreen />
				</AuthSensitiveRoute>
				<Route>
					<Redirect to="/" />
				</Route>
			</Switch>
		</Router>
	)
}

export default AppRouter
