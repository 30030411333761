/** @jsx jsx */
import {jsx, css} from '@emotion/core'
import {useAsync} from 'react-async'
import {Typography} from 'antd'
import LoginForm from './components/LoginForm'
import {useApi} from '../../utils/ApiProvider'
import {useAppState} from '../../state'

const {Title, Paragraph} = Typography

const container = css`
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1rem;
`

const formContainer = css`
	background: white;
	width: 100%;
	max-width: 30rem;
	margin: 0 auto;
	padding: 2rem;
`

function LoginScreen() {
	const api = useApi()
	const {auth} = useAppState()
	const {data: loggedIn, error, isPending, run} = useAsync({
		deferFn: async ([{id, secret}]) => {
			const {jwt} = await api.login({id, secret})
			auth.setJwt(jwt)
			return true
		},
	})

	const onLoginSubmit = ({id, secret}: {id: string; secret: string}) => {
		run({id, secret})
	}

	return (
		<div css={container}>
			<div css={formContainer}>
				<Title level={1}>Ynventive</Title>
				<Paragraph type="secondary">
					To login use your credentials or access the link we have send you.
				</Paragraph>
				<LoginForm
					loading={isPending || loggedIn}
					loginError={error}
					onSuccessSubmit={onLoginSubmit}
				/>
			</div>
		</div>
	)
}

export default LoginScreen
