/** @jsx jsx */
import {jsx, css} from '@emotion/core'
import {ModeTheme} from '../../../../api/types/modes'

const redBack = require('../images/redBack.svg')
const blackBack = require('../images/blackBack.svg')
const darkblueBack = require('../images/darkblueBack.svg')
const lightblueBack = require('../images/lightblueBack.svg')
const orangeBack = require('../images/orangeBack.svg')

const container = css`
	border-radius: 4px;
`

function image(mode: ModeTheme) {
	switch (mode) {
		case ModeTheme.BLACK:
			return blackBack
		case ModeTheme.RED:
			return redBack
		case ModeTheme.DARKBLUE:
			return darkblueBack
		case ModeTheme.LIGHTBLUE:
			return lightblueBack
		case ModeTheme.ORANGE:
			return orangeBack
		default:
			return null
	}
}

function shadow(mode: ModeTheme) {
	switch (mode) {
		case ModeTheme.BLACK:
			return css`
				box-shadow: 0px 0px 32px rgba(58, 59, 81, 0.24);
			`
		case ModeTheme.DARKBLUE:
			return css`
				box-shadow: 0px 0px 32px rgba(62, 66, 148, 0.24);
			`
		case ModeTheme.LIGHTBLUE:
			return css`
				box-shadow: 0px 0px 32px rgba(51, 133, 217, 0.24);
			`
		case ModeTheme.ORANGE:
			return css`
				box-shadow: 0px 0px 32px rgba(254, 187, 139, 0.32);
			`
		case ModeTheme.RED:
			return css`
				box-shadow: 0px 0px 32px rgba(255, 153, 148, 0.24);
			`
		default:
			return null
	}
}

interface Props {
	theme: ModeTheme
	className?: string
}

function ModeBackground({theme, className}: Props) {
	return (
		<img
			css={[container, shadow(theme)]}
			src={image(theme)}
			alt=""
			className={className}
		/>
	)
}

export default ModeBackground
