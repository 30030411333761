/** @jsx jsx */
import React, {useEffect} from 'react'
import {useAsync} from 'react-async'
import {jsx, css} from '@emotion/core'
import {useObserver} from 'mobx-react-lite'
import {useSnackbar} from 'notistack'
import {Route, Switch} from 'react-router-dom'
import {useAppState} from '../state'
import {SocketApiProvider} from '../utils/SocketApiProvider'
import processSocketInput from '../utils/processSocketInput'
import createSocketConnection, {SocketConnection} from '../../api/socketApi'
import HomeStore from '../state/HomeStore'
import InsideFooter from './InsideFooter'
import LoadingScreen from './LoadingScreen'
import FlatScreen from './FlatScreen'
import SettingsScreen from './SettingsScreen'

const root = css`
	background: white;
	min-height: 100%;
	max-width: 35rem;
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
`

async function loadSocketConnection({
	getAuthToken,
	homeStore,
	onConnectionLost,
	onReconnected,
	onErrorReceived,
	onDbDied,
}: {
	getAuthToken: () => string
	homeStore: HomeStore
	onConnectionLost: () => void
	onReconnected: (conn: SocketConnection) => void
	onErrorReceived: (err: any) => void
	onDbDied: () => void
}) {
	return createSocketConnection({
		url: process.env.REACT_APP_SOCKET_URL,
		path: process.env.REACT_APP_SOCKET_PATH,
		getAuthToken,
		onUpdateReceived: data => processSocketInput(data, homeStore),
		onConnectionLost,
		onReconnected,
		onErrorReceived,
		onDbDied,
	})
}

const RECONNECTING_SNACKBAR_KEY = 'ReconnectingSnackbar'

function InsideScreen() {
	const {auth, home} = useAppState()
	const {enqueueSnackbar, closeSnackbar} = useSnackbar()
	const socketApiLoading = useAsync({
		promiseFn: loadSocketConnection,
		getAuthToken: () => auth.jwt,
		homeStore: home,
		onConnectionLost: () => {
			enqueueSnackbar(
				'Lost connection to the server. Any interactions will not be applied. Trying to reconnect.',
				{
					key: RECONNECTING_SNACKBAR_KEY,
					persist: true,
					variant: 'error',
				}
			)
		},
		onReconnected: (connection: SocketConnection) => {
			closeSnackbar(RECONNECTING_SNACKBAR_KEY)
			enqueueSnackbar(
				'Connection successfully restored. You can now interact with your home again.',
				{variant: 'success'}
			)
			connection.requestAll()
		},
		onErrorReceived: (error: any) => {
			console.log('Error received', {error})
			enqueueSnackbar(error.message || 'There was an unexpected error', {
				variant: 'error',
			})
		},
		onDbDied: () => {
			auth.logout()
			enqueueSnackbar(
				'You were logged out. Please login again to continue using the app.'
			)
		},
	})

	useEffect(() => {
		if (!home.initialResponseReceived && socketApiLoading.isFulfilled) {
			socketApiLoading.data.requestAll()
		}
	}, [
		home.initialResponseReceived,
		socketApiLoading.data,
		socketApiLoading.isFulfilled,
	])

	// make sure to close socket connection on unmount
	useEffect(() => {
		return () => socketApiLoading.data?.close()
	}, [socketApiLoading])

	return useObserver(() => (
		<div css={root}>
			{socketApiLoading.isPending || !home.initialResponseReceived ? (
				<React.Fragment>
					{socketApiLoading.isPending && (
						<LoadingScreen message="Connecting to API" />
					)}
					{!socketApiLoading.isPending && !home.initialResponseReceived && (
						<LoadingScreen message="Loading initial data from API" />
					)}
				</React.Fragment>
			) : (
				<SocketApiProvider socketConnection={socketApiLoading.data}>
					<Switch>
						<Route path="/settings" exact>
							<SettingsScreen />
						</Route>
						<Route path="/">
							<FlatScreen />
						</Route>
					</Switch>
					<InsideFooter />
				</SocketApiProvider>
			)}
		</div>
	))
}

export default InsideScreen
