/* eslint-disable react/jsx-fragments */
/** @jsx jsx */
import {jsx, css} from '@emotion/core'
import {Fragment, useState} from 'react'
import {Spin} from 'antd'
import {useObserver} from 'mobx-react-lite'
import {useTransition} from 'react-spring'
import RoomControl from './components/RoomControl'
import {useAppState} from '../../state'
import FloorView from './components/FloorView'
import Container from '../Container'

const loadingContainer = css`
	display: flex;
	align-items: center;
	justify-content: center;
`

const container = css`
	flex: 1;
	display: flex;
	padding-top: 0.65rem;
	padding-bottom: 0;
`

const floor = css`
	flex: 1;
`

function LoadingView() {
	return (
		<div css={loadingContainer}>
			<Spin />
		</div>
	)
}

function FlatControl() {
	const {home} = useAppState()
	const [selectedRoomId, selectRoomId] = useState<string>(null)

	const roomControlTransition = useTransition(selectedRoomId, null, {
		from: {opacity: 0, transform: 'translateY(100%)'},
		enter: {opacity: 1, transform: 'translateY(0)'},
		leave: {opacity: 0, transform: 'translateY(100%)'},
	})

	return useObserver(() => (
		<Container css={container}>
			{!!home.rooms.length}
			{!home.initialResponseReceived ? (
				<LoadingView />
			) : (
				<Fragment>
					<FloorView
						css={floor}
						onSelectRoom={selectRoomId}
						selectedRoomId={selectedRoomId}
					/>
					{roomControlTransition.map(({item, props, key}) => (
						<RoomControl
							key={key}
							animationStyles={props}
							onHideClick={() => selectRoomId(null)}
							room={home.rooms.find(one => one.id === item)}
						/>
					))}
				</Fragment>
			)}
		</Container>
	))
}

export default FlatControl
