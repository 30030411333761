import React, {useEffect} from 'react'
import {Form, Input, Button, Icon, Typography} from 'antd'
import {FormComponentProps} from 'antd/lib/form'
import {hasErrors} from '../../../utils/forms'
import ErrorText from '../../ErrorText'

const {Paragraph} = Typography

interface Props extends FormComponentProps {
	onSuccessSubmit(fields: {[field: string]: any}): void
	loading: boolean
	loginError: Error
}

const ID_FIELD = 'id'
const SECRET_FIELD = 'secret'

function LoginForm({
	form,
	onSuccessSubmit,
	loginError,
	loading,
	...props
}: Props) {
	const {
		getFieldDecorator,
		getFieldsError,
		getFieldError,
		isFieldTouched,
		validateFields,
		getFieldsValue,
	} = form

	useEffect(() => {
		validateFields()
	}, [validateFields])

	const handleSubmit = e => {
		e.preventDefault()
		const {id, secret} = getFieldsValue()
		onSuccessSubmit({id, secret})
	}

	const idError = isFieldTouched(ID_FIELD) && getFieldError(ID_FIELD)
	const secretError =
		isFieldTouched(SECRET_FIELD) && getFieldError(SECRET_FIELD)

	return (
		<Form {...props} onSubmit={handleSubmit}>
			<Form.Item validateStatus={idError ? 'error' : ''} help={idError || ''}>
				{getFieldDecorator('id', {
					rules: [{required: true, message: 'Id must not be empty'}],
				})(
					<Input
						prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}} />}
						placeholder="id"
					/>
				)}
			</Form.Item>

			<Form.Item
				validateStatus={secretError ? 'error' : ''}
				help={secretError || ''}
			>
				{getFieldDecorator('secret', {
					rules: [{required: true, message: 'Secret must not be empty'}],
				})(
					<Input
						prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}} />}
						type="password"
						placeholder="secret"
					/>
				)}
			</Form.Item>
			<Form.Item>
				{!!loginError && (
					<Paragraph type="danger">
						<ErrorText error={loginError} />
					</Paragraph>
				)}
				<Button
					type="primary"
					htmlType="submit"
					disabled={hasErrors(getFieldsError())}
					loading={loading}
				>
					Log in
				</Button>
			</Form.Item>
		</Form>
	)
}

export default Form.create<Props>({name: 'LoginForm'})(LoginForm)
