import ApiError from './ApiError'

export const ERROR_CODE = 'badCredentials'

export default class BadCredentialError extends ApiError {
	constructor() {
		super({
			message: 'Bad credentials',
			errorCode: ERROR_CODE,
			status: 401,
		})
	}
}
