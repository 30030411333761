import {observable, computed, action, autorun} from 'mobx'
import sysed from 'sysend'
import jwt from 'jsonwebtoken'
import * as types from '../../api/types/auth'

const KEY_USER = 'userJwt'
const EVENT_USER_LOGIN_CHANGED = 'userJwt'

function storeJwt(userJwt?: string) {
	if (userJwt) localStorage.setItem(KEY_USER, userJwt)
	else localStorage.removeItem(KEY_USER)
}

function getJwt() {
	return localStorage.getItem(KEY_USER)
}

export default class AuthStore {
	@observable jwt?: string

	@computed get user(): types.User | null {
		if (!this.jwt) return null
		return jwt.decode(this.jwt) as types.User
	}

	@computed get isLoggedIn(): boolean {
		return !!this.jwt
	}

	@action setJwt(receivedJwt: string) {
		this.jwt = receivedJwt
	}

	@action logout = () => {
		this.jwt = undefined
	}
}

export function createAuthStore(): AuthStore {
	const authStore = new AuthStore()
	const storedJwt = getJwt()
	if (storedJwt) authStore.setJwt(storedJwt)

	autorun(() => {
		storeJwt(authStore.jwt)
		sysed.broadcast(
			EVENT_USER_LOGIN_CHANGED,
			authStore.jwt ? 'login' : 'logout'
		)
	})

	sysed.on(EVENT_USER_LOGIN_CHANGED, (message: string) => {
		if (message === 'logout') {
			authStore.logout()
			return
		}
		const newJwt = getJwt()
		if (newJwt) authStore.setJwt(newJwt)
	})

	return authStore
}
