/* eslint-disable indent */
/** @jsx jsx */
import {jsx, css} from '@emotion/core'
import styled from '@emotion/styled'
import {useObserver} from 'mobx-react-lite'
import {useAppState} from '../../../state'

const lightOffIcon = require('../images/lightOffIcon.svg')
const fanOffIcon = require('../images/fanOffIcon.svg')
const heatingOffIcon = require('../images/heatingOffIcon.svg')
const coolingOffIcon = require('../images/coolingOffIcon.svg')

interface RoomProps {
	x: number
	y: number
	width: number
	height: number
	hasLight: boolean
	isSelected: boolean
	isSelectable: boolean
}
const container = css`
	width: 100%;
	overflow: scroll;
	margin: 0 -8px;
	padding-top: 0.5rem;
`
const Room = styled('div', {
	shouldForwardProp: prop =>
		['hasLight', 'isSelected', 'isSelectable'].indexOf(prop) === -1,
})<RoomProps>`
	position: absolute;
	border: none;
	background: transparent;
	color: inherit;
	${props => !props.isSelected && props.hasLight && `cursor: pointer;`}

	${props => `
		left: ${(props.x / 100) * 515}px;
		top: ${(props.y / 100) * 515}px;
		width: ${(props.width / 100) * 515}px;
		height: ${(props.height / 100) * 515}px;

	`}

	&::after {
		content: '';
		position: absolute;
		left: 4px;
		right: 4px;
		bottom: 4px;
		top: 4px;
		transition: 200ms ease;
		border-radius: 8px;
		background: ${props => (props.isSelectable ? '#d2d7db' : '#f2f4f4')};
		${props => props.isSelected && `background: #B7B9BA;`}
	}
	&.active::after {
		border-style: solid;
		border-color: #6b6b6b;
	}
	@media (hover: hover) {
		${props =>
			props.hasLight &&
			`&:hover {
		&::after {
			background: #B7B9BA;
		}
		color: inherit;
	}`}
	}
`

const roomsContainer = css`
	padding: 0 -7px;
	position: relative;
	width: 100%;
	height: 100%;
	min-height: 10rem;
`

const name = css`
	position: absolute;
	z-index: 2;
	font-size: 0.7rem;
	right: 0.6rem;
	left: 0.6rem;
	top: 0.3rem;
	text-align: left;
	text-overflow: clip;
	overflow: hidden;
	white-space: break-spaces;
	opacity: 0.5;

	&& {
		color: #21354d;
	}
`

const indicators = css`
	position: absolute;
	left: 0rem;
	right: 0rem;
	bottom: 0rem;
	z-index: 1;
	margin: 0.3rem;
	display: block;
	align-items: center;
	max-height: 60%;
	overflow: hidden;

	img {
		width: 100%;
		max-width: 1.4rem;
		margin: 0.1rem;
		padding: 0.12rem;
		border-radius: 0.2rem;
	}
`

type Props = {
	selectedRoomId: string
	onSelectRoom: (id: string) => void
	className?: string
}

function FloorView({selectedRoomId, onSelectRoom, className}: Props) {
	const {home} = useAppState()
	return useObserver(() => (
		<div className={className} css={container}>
			<div css={roomsContainer}>
				{home.rooms.map(one => {
					return (
						<Room
							onClick={() => (one.hasLight || one.hasFan || one.hasHeating || one.hasCooling) && onSelectRoom(one.id)}
							key={one.id}
							x={one.position.x}
							y={one.position.y}
							width={one.position.width}
							height={one.position.height}
							isSelected={one.id === selectedRoomId}
							hasLight={one.hasLight || one.hasFan || one.hasHeating || one.hasCooling}
							isSelectable={one.hasLight || one.hasFan || one.hasHeating || one.hasCooling}
						>
							<div css={indicators}>
								{one.hasLight && (
									<img
										src={lightOffIcon}
										alt=""
										css={home.isLightOn(one.id) ? css`background-color: #ffff0064` : css``}
									/>
								)}
								{one.hasFan && (
									<img
										src={fanOffIcon}
										alt=""
										css={home.isFanOn(one.id) ? css`background-color: #00ff0064` : css``}
									/>
								)}
								{one.hasHeating && (
									<img
										src={heatingOffIcon}
										alt=""
										css={home.isHeatingOn(one.id) ? css`background-color: #ff000064` : css``}
									/>
								)}
								{one.hasCooling && (
									<img
										src={coolingOffIcon}
										alt=""
										css={home.isCoolingOn(one.id) ? css`background-color: #00beff64` : css``}
									/>
								)}
							</div>
							<div css={name}>{one.displayName}</div>
						</Room>
					)
				})}
			</div>
		</div>
	))
}

export default FloorView
