import React, {useState} from 'react'
import {SnackbarProvider} from 'notistack'
import {useObserver} from 'mobx-react-lite'
import HttpsRedirect from 'react-https-redirect'
import Router from './components/RootRouter'
import GlobalStyles from './components/GlobalStyles'
import {ApiProvider} from './utils/ApiProvider'
import {AppStateProvider, createStores} from './state'

function App() {
	const [stores] = useState(createStores())
	// @ts-ignore
	window.stores = stores

	return useObserver(() => (
		<div className="App">
			<HttpsRedirect disabled={process.env.NODE_ENV === 'development'}>
				<SnackbarProvider>
					<AppStateProvider stores={stores}>
						<ApiProvider
							socketApiUrl={process.env.REACT_APP_SOCKET_URL}
							socketApiPath={process.env.REACT_APP_SOCKET_PATH}
							authStore={stores.auth}
							apiURL={process.env.REACT_APP_API_URL}
						>
							<GlobalStyles />
							<Router />
						</ApiProvider>
					</AppStateProvider>
				</SnackbarProvider>
			</HttpsRedirect>
		</div>
	))
}

export default App
