/** @jsx jsx */
import {jsx, Global, css} from '@emotion/core'

const globalStyles = css`
	html {
		height: 100%;
	}
	html,
	body {
		background-color: #e0e0e0;
		min-height: 100%;
	}

	.App,
	#root {
		height: 100%;
	}

	/* antd styling */
	.ant-slider-track {
		background: #4d5d72 !important;
	}

	.ant-slider-handle {
		border-color: #4d5d72 !important;
		box-shadow: none !important;
	}

	.ant-switch-checked {
		background: #4d5d72 !important;
	}

	.ant-popover-inner-content {
		/* padding: 0;*/
	}
`

export default () => <Global styles={globalStyles} />
