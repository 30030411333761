/** @jsx jsx */

import {jsx, css} from '@emotion/core'
import {Slider, Switch, Typography} from 'antd'
import {LightData} from '../../../../api/types/lights'
import {useSocketApi} from '../../../utils/SocketApiProvider'
import useSyncingValue from '../../../utils/useSyncingValue'
import {useAppState} from '../../../state'
import IconInSquare from './IconInSquare'

const lightIcon = require('../images/lightIcon.svg')

type Props = {
	light: LightData
}

const titleRow = css`
	display: flex;
	align-items: center;
	margin-bottom: 1.1rem;
`
const title = css`
	flex: 1;
	margin-left: 0.8rem;
	font-size: 1rem;
	font-weight: 600;
`
const icon = css``

const container = css`
	user-select: none;
`

const slider = css`
	margin: 0;
`

const UPDATE_TIMEOUT_MS = 10000

function LightControl({light}: Props) {
	const api = useSocketApi()
	const {home} = useAppState()
	const {
		value: {current, previous},
		setValue,
		cancelSyncing,
		resumeSyncing,
	} = useSyncingValue<{
		current: number
		previous: number
	}>({
		getValue: () => {
			const {currentState, previousState} = home.lights.find(
				one => one.id === light.id
			).state
			return {current: currentState, previous: previousState}
		},
		intervalMs: UPDATE_TIMEOUT_MS,
	})

	const setLight = async (newValue: number) => {
		setValue({current: newValue, previous: current})
		api.setLightState({id: light.id, state: newValue})
	}

	return (
		<div css={container}>
			<div css={titleRow}>
				<IconInSquare css={icon} src={lightIcon} alt="" />
				<Typography.Text css={title}>Light control</Typography.Text>
				<Switch
					checked={current > 0}
					onChange={checked => {
						const previousEnabled = previous || 99
						const newValue = checked ? previousEnabled : 0
						setLight(newValue)
					}}
				/>
			</div>
			<Slider
				value={current}
				css={slider}
				min={current > 0 ? 1 : 0}
				max={99}
				onChange={(newValue: number) => {
					setValue({current: newValue, previous: current})
					cancelSyncing()
				}}
				onAfterChange={(newValue: number) => {
					resumeSyncing()
					setLight(newValue)
				}}
			/>
		</div>
	)
}

export default LightControl
