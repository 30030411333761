import BadCredentialError, {
	ERROR_CODE as BadCredentialErrorCode,
} from './BadCredentialError'
import DbConnectionError, {
	ERROR_CODE as DbConnectionErrorCode,
} from './DbConnectionError'
import ApiError from './ApiError'

export function responseToError(axiosError: any) {
	if (
		!axiosError.response ||
		!axiosError.response.data ||
		!axiosError.response.data.errorCode ||
		!axiosError.response.data.message
	) {
		return axiosError
	}
	const {data} = axiosError.response

	switch (data.errorCode) {
		case BadCredentialErrorCode:
			return new BadCredentialError()
		case DbConnectionErrorCode:
			return new DbConnectionError(data.message)
		default:
			return new ApiError({
				message: data.message,
				errorCode: data.errorCode,
				status: axiosError.response.status,
			})
	}
}
