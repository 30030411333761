import {observable, action, IObservableArray, computed, autorun} from 'mobx'
import {TemperatureData} from '../../api/types/temperatures'

import {Units} from '../../api/types/units'
import {HeatingData} from '../../api/types/heating'
import {CoolingData} from '../../api/types/cooling'
import {CurrentModeState, ModeData} from '../../api/types/modes'

import {LightData} from '../../api/types/lights'
import {FanData} from '../../api/types/fan'
import {RoomData} from '../../api/types/rooms'
import {SocketUpdateData} from '../../api/socketApi'
import AuthStore from './AuthStore'

function updateArray<T extends {id: string}>({
	target,
	updates,
}: {
	target: IObservableArray<T>
	updates: T[]
}) {
	updates.forEach(oneUpdate => {
		const alreadyExistingIndex = target.findIndex(
			oldMode => oldMode.id === oneUpdate.id
		)
		if (alreadyExistingIndex !== -1) target[alreadyExistingIndex] = oneUpdate
		else target.push(oneUpdate)
	})
}
export default class HomeStore {
	@observable rooms: IObservableArray<RoomData> = observable.array<RoomData>([])
	@observable lights: IObservableArray<LightData> = observable.array<LightData>(
		[]
	)
	@observable fan: IObservableArray<FanData> = observable.array<FanData>(
		[]
	)
	@observable cooling: IObservableArray<CoolingData> = observable.array<
		CoolingData
	>()
	@observable heating: IObservableArray<HeatingData> = observable.array<
		HeatingData
	>()
	@observable modes: IObservableArray<ModeData> = observable.array<ModeData>([])
	@observable temperatures: IObservableArray<
		TemperatureData
	> = observable.array<TemperatureData>([])
	@observable currentModeState: CurrentModeState = {
		currentModeId: '',
		nextModeId: '',
		currentModeTime: '',
		nextModeTime: '',
	}
	@observable units: Units = {temperature: 'C'}

	@observable initialResponseReceived: boolean = false

	getLightsInRoom(roomId: string): LightData {
		return this.lights.filter(dev => dev.roomId === roomId)[0]
	}

	getFanInRoom(roomId: string): FanData {
		return this.fan.filter(dev => dev.roomId === roomId)[0]
	}

	getCoolingInRoom(roomId: string): CoolingData {
		return this.cooling.filter(dev => dev.roomId === roomId)[0]
	}

	getHeatingInRoom(roomId: string): HeatingData {
		return this.heating.filter(dev => dev.roomId === roomId)[0]
	}

	isLightOn(roomId: string): boolean {
		return this.getLightsInRoom(roomId)?.state.currentState > 0
	}
	isFanOn(roomId: string): boolean {
		return this.getFanInRoom(roomId)?.state.currentState > 0
	}
	isCoolingOn(roomId: string): boolean {
		return this.getCoolingInRoom(roomId)?.state.currentState > 0
	}
	isHeatingOn(roomId: string): boolean {
		return this.getHeatingInRoom(roomId)?.state.currentState > 0
	}

	@computed get defaultMode(): ModeData {
		return this.modes.find(mode => mode.id === 'DEFAULT') || this.modes[0]
	}

	@computed get currentModeFull(): ModeData | undefined {
		return (
			this.modes.find(one => one.id === this.currentModeState.currentModeId) ||
			this.defaultMode
		)
	}

	@computed get nextModeFull(): ModeData | undefined {
		return this.modes.find(one => one.id === this.currentModeState.nextModeId)
	}

	getRoomTemperature(roomId: string): TemperatureData | undefined {
		return this.temperatures.find(one => one.roomId === roomId)
	}

	@action clear() {
		this.rooms.clear()
		this.lights.clear()
		this.fan.clear()
		this.cooling.clear()
		this.heating.clear()
		this.modes.clear()
		this.temperatures.clear()
		this.currentModeState = {
			currentModeId: '',
			nextModeId: '',
			currentModeTime: '',
			nextModeTime: '',
		}
		this.units = {temperature: 'C'}
		this.initialResponseReceived = false
	}

	@action overrideAll({
		lights,
		fan,
		rooms,
		heating,
		cooling,
		modes,
		currentModeState,
		units,
		temperatures,
	}: SocketUpdateData) {
		this.rooms.replace(rooms)
		this.lights.replace(lights)
		this.fan.replace(fan)
		this.modes.replace(modes)
		this.heating.replace(heating)
		this.cooling.replace(cooling)
		this.temperatures.replace(temperatures)
		if (units) this.units = units
		if (currentModeState) this.currentModeState = currentModeState

		this.initialResponseReceived = true
	}

	@action update({
		lights,
		fan,
		rooms,
		modes,
		heating,
		cooling,
		currentModeState,
		units,
		temperatures,
	}: SocketUpdateData) {
		updateArray({target: this.lights, updates: lights})
		updateArray({target: this.fan, updates: fan})
		updateArray({target: this.rooms, updates: rooms})
		updateArray({target: this.modes, updates: modes})
		updateArray({target: this.cooling, updates: cooling})
		updateArray({target: this.heating, updates: heating})
		updateArray({target: this.temperatures, updates: temperatures})
		if (currentModeState) this.currentModeState = currentModeState
		if (units) this.units = units
	}
}

export function createHomeStore({authStore}: {authStore: AuthStore}) {
	const homeStore = new HomeStore()
	autorun(() => {
		if (!authStore.isLoggedIn) homeStore.clear()
	})
	return homeStore
}
