import socket, {Socket} from 'socket.io-client'
import * as Sentry from '@sentry/browser'
import {TemperatureData} from './types/temperatures'
import {Units} from './types/units'
import {CoolingData} from './types/cooling'
import {RoomData} from './types/rooms'
import {ModeData, ModeTheme, CurrentModeState} from './types/modes'
import {LightData} from './types/lights'
import {FanData} from './types/fan'
import {HeatingData} from './types/heating'

export const EVENTS = {
	GET_ALL: 'initGetAll',
	SET_LIGHT_STATE: 'setLightState',
	SET_FAN_STATE: 'setFanState',
	SET_COOLING_STATE: 'setCoolingState',
	SET_HEATING_STATE: 'setHeatingState',
	SET_CURRENT_MODE: 'setMode',
	DB_DIED: 'dbDied',
	UNEXPECTED_DB_ERROR: 'unexpectedDbError',
	SERVER_READY: 'serverReady',
}

export interface SocketConnection {
	requestAll(): void
	setLightState(args: {id: string; state: number}): void
	setFanState(args: {id: string; state: number}): void
	setHeatingState(args: {id: string; state: number}): void
	setCoolingState(args: {id: string; state: number}): void
	setCurrentMode({id}: {id: string}): void
	connection: typeof Socket
	close: () => void
}

export interface SocketUpdateData {
	lights: LightData[]
	fan: FanData[]
	rooms: RoomData[]
	modes: ModeData[]
	heating: HeatingData[]
	cooling: CoolingData[]
	temperatures: TemperatureData[]
	currentModeState?: CurrentModeState
	units?: Units
}

function themeStringToEnum(themeString: string) {
	switch (themeString) {
		case ModeTheme.BLACK.toString():
			return ModeTheme.BLACK
		case ModeTheme.DARKBLUE.toString():
			return ModeTheme.DARKBLUE
		case ModeTheme.LIGHTBLUE.toString():
			return ModeTheme.LIGHTBLUE
		case ModeTheme.RED.toString():
			return ModeTheme.RED
		case ModeTheme.ORANGE.toString():
			return ModeTheme.ORANGE
		default:
			return ModeTheme.DARKBLUE
	}
}

export default function createSocketConnection({
	url,
	path,
	getAuthToken,
	onUpdateReceived,
	onErrorReceived,
	onConnectionLost,
	onDbDied,
	onReconnected,
}: {
	url: string
	path: string
	getAuthToken: () => string
	onUpdateReceived: (args: {
		type: 'set' | 'update'
		data: SocketUpdateData
	}) => void
	onConnectionLost: () => void
	onDbDied: () => void
	onReconnected: (connection: SocketConnection) => void
	onErrorReceived: (error: any) => void
}): Promise<SocketConnection> {
	return new Promise(resolve => {
		const connection = socket(url, {
			forceNew: true,
			path,
			query: {token: getAuthToken()},
		})
		let connectionLostReported = false

		connection.on('message', (data: any) => {
			console.log('Received message from the server', data)
			onUpdateReceived({
				...data,
				modes: data.modes?.map((oneMode: any) => ({
					...oneMode,
					theme: themeStringToEnum(oneMode.theme),
				})),
			})
		})

		connection.on('error', (e: any) => {
			console.warn(
				'Un unexpected error has occured. Please refresh page if necessary',
				e
			)
			Sentry.captureEvent(e)
		})
		connection.on(EVENTS.UNEXPECTED_DB_ERROR, (e: any) => {
			console.warn(
				'There was an error while submitting your data. Please try to submit them again.',
				e
			)
			Sentry.captureException(e)
			onErrorReceived(e)
		})

		connection.on(EVENTS.DB_DIED, () => {
			onDbDied()
			connection.removeAllListeners()
		})

		const socApi = {
			connection,
			requestAll() {
				connection.send({type: EVENTS.GET_ALL})
			},
			setLightState({id, state}: {id: string; state: number}) {
				connection.send({type: EVENTS.SET_LIGHT_STATE, data: {id, state}})
			},
			setFanState({id, state}: {id: string; state: number}) {
				connection.send({type: EVENTS.SET_FAN_STATE, data: {id, state}})
			},
			setCurrentMode({id}: {id: string}) {
				connection.send({type: EVENTS.SET_CURRENT_MODE, data: {id}})
			},
			setHeatingState({id, state}: {id: string; state: number}) {
				connection.send({type: EVENTS.SET_HEATING_STATE, data: {id, state}})
			},
			setCoolingState({id, state}: {id: string; state: number}) {
				connection.send({type: EVENTS.SET_COOLING_STATE, data: {id, state}})
			},
			close() {
				connection.removeAllListeners()
				connection.close()
			},
		}

		connection.on('disconnect', () => {
			connection.once(EVENTS.SERVER_READY, () => {
				socApi.requestAll()
				if (connectionLostReported) onReconnected(socApi)
				connectionLostReported = false
			})
		})

		connection.on('reconnect_attempt', (number: number) => {
			if (number > 1 && !connectionLostReported) {
				onConnectionLost()
				connectionLostReported = true
			}
		})

		connection.once(EVENTS.SERVER_READY, () => {
			resolve(socApi)
		})
	})
}
