/** @jsx jsx */
import {jsx} from '@emotion/core'
import PlusMinusControl from './PlusMinusControl'
import {useSocketApi} from '../../../utils/SocketApiProvider'
import {useAppState} from '../../../state'
import useSyncingValue from '../../../utils/useSyncingValue'
import {CoolingData} from '../../../../api/types/cooling'

const coolingIcon = require('../images/coolingIcon.svg')

function CoolingControl({cooling}: {cooling: CoolingData}) {
	const api = useSocketApi()
	const {home} = useAppState()
	const {
		value: {currentState},
		setValue,
	} = useSyncingValue<{currentState: number}>({
		getValue: () => {
			const {state} = home.cooling.find(one => one.id === cooling.id)
			return {
				currentState: state.currentState,
			}
		},
		intervalMs: 6000,
	})

	return (
		<PlusMinusControl
			icon={coolingIcon}
			name="Cooling"
			description="Start cooling above"
			afterValue={`°${home.units.temperature}`}
			value={Math.abs(currentState)}
			onChange={newValue => {
				if (newValue <= 0) return
				api.setCoolingState({id: cooling.id, state: newValue})
				setValue({currentState: newValue})
			}}
			onEnable={enabled => {
				const newValue = enabled ? Math.abs(currentState) : -Math.abs(currentState)
				api.setCoolingState({
					id: cooling.id,
					state: newValue,
				})
				setValue({currentState: newValue})
			}}
			enabled={currentState > 0}
		/>
	)
}

export default CoolingControl
