import React, {useContext, createContext} from 'react'
import AuthStore, {createAuthStore} from './AuthStore'
import HomeStore, {createHomeStore} from './HomeStore'

export interface Stores {
	auth: AuthStore
	home: HomeStore
}

export function createStores(): Stores {
	const auth = createAuthStore()
	return {
		auth,
		home: createHomeStore({authStore: auth}),
	}
}

const storesContext = createContext<Stores>({
	auth: new AuthStore(),
	home: new HomeStore(),
})

export function AppStateProvider({
	stores,
	children,
}: {
	stores: Stores
	children: React.ReactNode
}) {
	return (
		<storesContext.Provider value={stores}>{children}</storesContext.Provider>
	)
}

export function useAppState(): Stores {
	return useContext(storesContext)
}
