/** @jsx jsx */
import {jsx, css} from '@emotion/core'
import {Typography} from 'antd'
import {ReactNode} from 'react'

const plusIcon = require('../images/plus.svg')
const minusIcon = require('../images/minus.svg')

const icon = css`
	background: #eaecee;
	border-radius: 4px;
	padding: 0 0.57rem;
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;
	align-self: stretch;
`

const valueText = css`
	margin: 0 0.78rem;
`

const container = css`
	display: flex;
	color: #424e5f;
	font-size: 1rem;
	font-weight: 800;
	align-items: center;
`

interface Props {
	value: number
	onChange: (value: number) => void
	step: number
	className?: string
	afterValue?: ReactNode
}
function PlusMinusInput({value, afterValue, onChange, step, className}: Props) {
	return (
		<div css={container} className={className}>
			<div css={icon} onClick={() => onChange(value - step)}>
				<img src={minusIcon} alt="-" />
			</div>
			<Typography.Text css={valueText}>
				{value}
				{afterValue}
			</Typography.Text>
			<div css={icon} onClick={() => onChange(value + step)}>
				<img src={plusIcon} alt="+" />
			</div>
		</div>
	)
}

export default PlusMinusInput
