/** @jsx jsx */
import React from 'react'
import {jsx, css} from '@emotion/core'
import FlatControl from './FlatControl'
import Header from './Header'
import ModeControl from './ModeControl'

const modeControl = css`
	margin-bottom: -2rem;
`
const header = css``

function FlatScreen() {
	return (
		<React.Fragment>
			<Header css={header} titleText="Your smarthome" withSettings>
				<ModeControl css={modeControl} />
			</Header>
			<FlatControl />
		</React.Fragment>
	)
}

export default FlatScreen
