/** @jsx jsx */
import {css, jsx} from '@emotion/core'
import {Spin, Typography} from 'antd'

const root = css`
	width: 100%;
	height: 100%;
	display: flex;
	flex: 1;
	align-items: center;
	flex-direction: column;
	justify-content: center;
`

const spin = css`
	margin-bottom: 2rem;
`

function LoadingScreen({message}: {message?: string}) {
	return (
		<div css={root}>
			<Spin size="large" css={spin} />
			{!!message && <Typography.Text>{message}</Typography.Text>}
		</div>
	)
}

export default LoadingScreen
