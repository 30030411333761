export enum ModeTheme {
	DARKBLUE = 'darkblue',
	LIGHTBLUE = 'lightblue',
	RED = 'red',
	ORANGE = 'orange',
	BLACK = 'black',
}
export interface ModeData {
	id: string
	name: string
	recurringRule: string
	theme: ModeTheme
}

export interface CurrentModeState {
	currentModeId: string
	nextModeId: string
	currentModeTime: string
	nextModeTime: string
}
