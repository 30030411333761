import {useRef} from 'react'

export default function useTimeout() {
	const updateTimeout = useRef<NodeJS.Timeout | null>(null)

	const cancelTimeout = () => {
		if (updateTimeout.current) clearTimeout(updateTimeout.current)
	}
	const startTimeout = (callback: () => void, timeoutMillisec: number) => {
		cancelTimeout()
		updateTimeout.current = setTimeout(callback, timeoutMillisec)
	}

	return {
		cancelTimeout,
		startTimeout,
	}
}
