/** @jsx jsx */
import React from 'react'
import {jsx} from '@emotion/core'
import {Button} from 'antd'
import {useHistory} from 'react-router-dom'
import {useAppState} from '../state'
import Header from './Header'
import Container from './Container'

function SettingsScreen() {
	const {auth} = useAppState()
	const history = useHistory()
	return (
		<React.Fragment>
			<Header titleText="Settings" />
			<Container>
				<Button onClick={history.goBack} type="primary">
					Go back
				</Button>
				<br />
				<br />
				<Button onClick={auth.logout} type="primary">
					Logout
				</Button>
			</Container>
		</React.Fragment>
	)
}

export default SettingsScreen
