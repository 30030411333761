/** @jsx jsx */
import {jsx} from '@emotion/core'
import PlusMinusControl from './PlusMinusControl'
import {useSocketApi} from '../../../utils/SocketApiProvider'
import {useAppState} from '../../../state'
import useSyncingValue from '../../../utils/useSyncingValue'
import {HeatingData} from '../../../../api/types/heating'

const heatingIcon = require('../images/heatingIcon.svg')

function HeatingControl({heating}: {heating: HeatingData}) {
	const api = useSocketApi()
	const {home} = useAppState()
	const {
		value: {currentState},
		setValue,
	} = useSyncingValue<{currentState: number}>({
		getValue: () => {
			const {state} = home.heating.find(one => one.id === heating.id)
			return {
				currentState: state.currentState,
			}
		},
		intervalMs: 6000,
	})

	return (
		<PlusMinusControl
			icon={heatingIcon}
			name="Heating"
			description="Start heating below"
			afterValue={`°${home.units.temperature}`}
			value={Math.abs(currentState)}
			onChange={newValue => {
				if (newValue <= 0) return
				api.setHeatingState({id: heating.id, state: newValue})
				setValue({currentState: newValue})
			}}
			onEnable={enabled => {
				const newValue = enabled ? Math.abs(currentState) : -Math.abs(currentState)
				api.setHeatingState({
					id: heating.id,
					state: newValue,
				})
				setValue({currentState: newValue})
			}}
			enabled={currentState > 0}
		/>
	)
}

export default HeatingControl
