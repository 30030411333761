import React from 'react'
import {Route, Redirect, RouteProps} from 'react-router-dom'
import {useObserver} from 'mobx-react-lite'
import {useAppState} from '../state'

interface Props extends RouteProps {
	mode: 'onlyLogged' | 'onlyNotLogged'
}

function AuthSensitiveRouter({mode, children, ...rest}: Props) {
	const {auth} = useAppState()
	return useObserver(() => (
		<>
			{/* This forces rerender when auth status changes */}
			{!!auth.isLoggedIn}
			<Route
				render={() => {
					if (mode === 'onlyLogged') {
						if (auth.isLoggedIn) return children
						return <Redirect to="/login" />
					}

					if (mode === 'onlyNotLogged') {
						if (!auth.isLoggedIn) return children
						return <Redirect to="/" />
					}

					return children
				}}
				{...rest}
			/>
		</>
	))
}

export default AuthSensitiveRouter
