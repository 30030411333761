import {useState, useEffect, useCallback} from 'react'
import useTimeout from './useTimeout'

export default function useSyncingState<T>({
	getValue,
	intervalMs,
}: {
	intervalMs: number
	getValue: () => T
}): {
	value: T
	setValue: (v: T) => void
	cancelSyncing: () => void
	resumeSyncing: () => void
} {
	const defaultValue = getValue()
	const [value, setValue] = useState(defaultValue)
	const [enabledSyncing, setEnabledSyncing] = useState(true)
	const {startTimeout, cancelTimeout} = useTimeout()

	const syncValue = useCallback(() => {
		const newValue = getValue()
		cancelTimeout()
		startTimeout(syncValue, intervalMs)
		setValue(newValue)
	}, [setValue, getValue, intervalMs, cancelTimeout, startTimeout])

	useEffect(() => {
		if (enabledSyncing) startTimeout(syncValue, intervalMs)
		return () => cancelTimeout()
	}, [cancelTimeout, enabledSyncing, intervalMs, syncValue, startTimeout])

	function handleSetValue(newValue: T) {
		cancelTimeout()
		if (enabledSyncing) startTimeout(syncValue, intervalMs)
		setValue(newValue)
	}

	return {
		value,
		setValue: handleSetValue,
		cancelSyncing: () => {
			cancelTimeout()
			setEnabledSyncing(false)
		},
		resumeSyncing: () => {
			cancelTimeout()
			startTimeout(syncValue, intervalMs)
			setEnabledSyncing(true)
		},
	}
}
