/** @jsx jsx */
import {jsx, css} from '@emotion/core'
import {Typography} from 'antd'
import {ModeData} from '../../../../api/types/modes'

const container = css`
	margin: -0.25rem 0;
	cursor: pointer;
`

const modeContainer = css`
	padding: 0.25rem 0;
	font-size: 1rem;
`

const modeSelectedContainer = css`
	text-decoration: underline;
`

interface Props {
	modes: ModeData[]
	selectedModeId: string
	onModeClicked: (mode: ModeData) => void
}

function SelectModeDialog({modes, selectedModeId, onModeClicked}: Props) {
	return (
		<div css={container}>
			{modes.map(mode => (
				<div
					key={mode.id}
					css={[
						modeContainer,
						selectedModeId === mode.id ? modeSelectedContainer : null,
					]}
					onClick={() => onModeClicked(mode)}
				>
					<Typography.Text>{mode.name}</Typography.Text>
				</div>
			))}
		</div>
	)
}

export default SelectModeDialog
