import axios, {AxiosInstance} from 'axios'
import * as routes from './routes'
import * as loginTypes from './types/auth'
import * as lightsTypes from './types/lights'
import * as roomsTypes from './types/rooms'
import {responseToError} from './errors'

function handleError(
	aInstance: AxiosInstance,
	logErrors: boolean | undefined = false
): AxiosInstance {
	aInstance.interceptors.response.use(
		res => res,
		error => {
			const {data} = error.response

			if (logErrors) {
				console.warn('Error while performing request', {
					response: error.response,
				})
			}

			if (data.errorCode && data.message) {
				const apiError = responseToError(error)
				return Promise.reject(apiError)
			}
			return Promise.reject(error)
		}
	)
	return aInstance
}

export interface ApiService {
	login(req: loginTypes.LoginRequest): Promise<loginTypes.LoginResponse>
}

export default function createApiService({
	apiURL,
	getAuthToken,
}: {
	apiURL: string
	getAuthToken: () => string
}): ApiService {
	const axiosInstance = handleError(
		axios.create({
			baseURL: apiURL,
		}),
		process.env.NODE_ENV === 'development'
	)

	// eslint-disable-next-line
	const axiosAuthInstance = handleError(
		axios.create({
			baseURL: apiURL,
		}),
		process.env.NODE_ENV === 'development'
	)
	axiosAuthInstance.interceptors.request.use(config => {
		config.headers.Authorization = `Bearer ${getAuthToken()}`

		return config
	})

	const login = async ({
		id,
		secret,
	}: loginTypes.LoginRequest): Promise<loginTypes.LoginResponse> => {
		const res = await axiosInstance.post(routes.LOGIN_USER, {id, secret})
		return res.data
	}

	return {
		login,
	}
}

export {routes, loginTypes, roomsTypes, lightsTypes}
