import React, {useContext, createContext} from 'react'
import createApiService, {ApiService} from '../../api'
import AuthStore from '../state/AuthStore'

const apiContext = createContext<ApiService>(
	// create dummy
	createApiService({
		apiURL: '/api',
		getAuthToken: () => '',
	})
)

export function ApiProvider({
	apiURL,
	authStore,
	children,
}: {
	apiURL: string
	authStore: AuthStore
	children: React.ReactNode
	socketApiUrl: string
	socketApiPath: string
}) {
	const apiService = createApiService({
		apiURL,
		getAuthToken: () => authStore.jwt,
	})
	return (
		<apiContext.Provider value={apiService}>{children}</apiContext.Provider>
	)
}

export function useApi(): ApiService {
	return useContext(apiContext)
}
