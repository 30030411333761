/** @jsx jsx */
import {useState, useEffect, useCallback} from 'react'
import {jsx, css} from '@emotion/core'
import {Typography, Popover} from 'antd'
import {useAppState} from '../../state'
import useTimeout from '../../utils/useTimeout'
import {useSocketApi} from '../../utils/SocketApiProvider'
import SelectModeDialog from './components/SelectModeDialog'
import {ModeData} from '../../../api/types/modes'
import ModeBackground from './components/ModeBackground'

const treeImg = require('./images/tree.svg')

const UPDATE_TIMEOUT_MS = 3000

const container = css`
	color: white;
	border-radius: 4px;
	position: relative;
	z-index: 2;
	user-select: none;
`

const innerContainer = css`
	display: flex;
	flex-direction: column;
	padding: 0.75rem 1.4rem;
	cursor: pointer;

	.ant-popover-title {
		font-size: 1rem;
	}

	&,
	> * {
		position: relative;
	}
`

const treeContainer = css`
	position: absolute;
	bottom: 1px;
	top: 0;
	overflow: hidden;
	right: 5%;
`

const tree = css`
	height: 100%;
`

const background = css`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
`

const descriptiveLabel = css`
	font-size: 0.85rem;
	margin-bottom: 0.5rem;
	opacity: 0.75;

	&& {
		line-height: 1;
		color: white;
	}
`

const modeTitle = css`
	font-size: 1.2rem;

	&& {
		line-height: 1;
		color: white;
	}
`

function ModeControl({className}: {className?: string}) {
	const {home} = useAppState()
	const socketApi = useSocketApi()
	const [popoverVisible, setPopoverVisible] = useState(false)
	const [selectedMode, setSelectedMode] = useState(
		home.currentModeFull || home.defaultMode
	)
	const {startTimeout, cancelTimeout} = useTimeout()

	const syncWithStore = useCallback(() => {
		setSelectedMode(home.currentModeFull)
		cancelTimeout()
		startTimeout(syncWithStore, UPDATE_TIMEOUT_MS)
	}, [setSelectedMode, startTimeout, cancelTimeout, home.currentModeFull])

	const handleModeChange = (newMode: ModeData) => {
		setPopoverVisible(false)
		setSelectedMode(newMode)
		socketApi.setCurrentMode({id: newMode.id})
		cancelTimeout()
		startTimeout(syncWithStore, UPDATE_TIMEOUT_MS)
	}

	useEffect(() => {
		startTimeout(syncWithStore, UPDATE_TIMEOUT_MS)
	}, [syncWithStore, startTimeout])

	return (
		<div className={className} css={container}>
			<Popover
				className="popover"
				title="Select mode"
				trigger="click"
				onVisibleChange={setPopoverVisible}
				visible={popoverVisible}
				content={
					<SelectModeDialog
						modes={home.modes}
						selectedModeId={selectedMode.id}
						onModeClicked={handleModeChange}
					/>
				}
				css={innerContainer}
			>
				<ModeBackground css={background} theme={selectedMode.theme} />
				<div css={treeContainer}>
					<img src={treeImg} alt="" css={tree} />
				</div>
				<Typography.Text css={descriptiveLabel}>Current mode</Typography.Text>
				<Typography.Text css={modeTitle}>{selectedMode?.name}</Typography.Text>
			</Popover>
		</div>
	)
}

export default ModeControl
