/** @jsx jsx */
import {jsx, css} from '@emotion/core'
import {Typography, Switch} from 'antd'
import {ReactNode} from 'react'
import IconInSquare from './IconInSquare'
import PlusMinusInput from './PlusMinusInput'

const topRow = css`
	display: flex;
	align-items: center;
	margin-bottom: 1.4rem;
`

const title = css`
	flex: 1;
	margin-left: 0.85rem;
	font-size: 1rem;
	font-weight: 600;
`

const descriptionStyle = css`
	opacity: 0.32;
	flex: 1;
`

const container = css``

const bottomRow = css`
	display: flex;
	align-items: center;
`

interface Props {
	icon: string
	name: string
	description: string
	value: number
	onChange: (val: number) => void
	enabled: boolean
	onEnable: (enabled: boolean) => void
	afterValue?: ReactNode
}

function PlusMinusControl({
	icon,
	name,
	afterValue,
	description,
	value,
	onChange,
	enabled,
	onEnable,
}: Props) {
	return (
		<div css={container}>
			<div css={topRow}>
				<IconInSquare src={icon} />
				<Typography.Text css={title}>{name}</Typography.Text>
				<Switch checked={enabled} onChange={checked => onEnable(checked)} />
			</div>
			<div />
			<div css={bottomRow}>
				<Typography.Text css={descriptionStyle}>{description}</Typography.Text>
				<PlusMinusInput
					step={1}
					onChange={onChange}
					value={value}
					afterValue={afterValue}
				/>
			</div>
		</div>
	)
}

export default PlusMinusControl
