/** @jsx jsx */
import {jsx, css} from '@emotion/core'
import {Typography} from 'antd'
import {useAppState} from '../../../state'
import {TemperatureData} from '../../../../api/types/temperatures'

const row = css`
	display: flex;
	align-items: center;
`

const label = css`
	color: #202f43;
	opacity: 0.32;
	flex: 1;
`

const value = css`
	font-weight: 800;
`

function CurrentTemperature({temperature}: {temperature: TemperatureData}) {
	const {home} = useAppState()
	return (
		<div css={row}>
			<Typography.Text css={label}>Current temperature</Typography.Text>
			<Typography.Text css={value}>
				{String(temperature.currentTemperature)}°{home.units.temperature}
			</Typography.Text>
		</div>
	)
}

export default CurrentTemperature
