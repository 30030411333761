/** @jsx jsx */
import {jsx, css} from '@emotion/core'
import {Typography, Icon} from 'antd'
import {Link} from 'react-router-dom'
import Container from './Container'

const container = css`
	padding: 0.25rem 1.14rem 0rem;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	position: relative;
	background: #202f43;
	margin-bottom: 1.333rem;

	> * {
		position: relative;
	}
`

const topSection = css`
	display: flex;
	align-items: baseline;
	padding: 0.5rem 0 0.75rem;
`
const settingsContainer = css`
	i {
		opacity: 0.32;
		font-size: 1.4rem;
		color: white;
	}
`

const title = css`
	flex: 1;
	&& {
		color: white;
		margin-bottom: 0;
	}
`

function Header({
	titleText,
	withSettings,
	className,
	children,
}: {
	titleText: string
	withSettings?: boolean
	children?: React.ReactNode
	className?: string
}) {
	return (
		<Container className={className} css={container}>
			<div css={topSection}>
				<Typography.Title level={4} css={title}>
					{titleText}
				</Typography.Title>
				{withSettings && (
					<Link to="/settings" css={settingsContainer}>
						<Icon type="setting" />
					</Link>
				)}
			</div>
			{children}
		</Container>
	)
}

export default Header
