import React, {useContext, createContext} from 'react'
import {Socket} from 'socket.io-client'
import {SocketConnection} from '../../api/socketApi'

const dummySocketConnection: SocketConnection = {
	connection: {} as typeof Socket,
	requestAll: () => undefined,
	setLightState: () => undefined,
	setCurrentMode: () => undefined,
	close: () => undefined,
}

const socketApiContext = createContext<SocketConnection>(dummySocketConnection)

export function SocketApiProvider({
	socketConnection,
	children,
}: {
	socketConnection: SocketConnection
	children: React.ReactNode
}) {
	// @ts-ignore
	window.socketConnection = socketConnection
	return (
		<socketApiContext.Provider value={socketConnection}>
			{children}
		</socketApiContext.Provider>
	)
}

export function useSocketApi(): SocketConnection {
	return useContext(socketApiContext)
}
