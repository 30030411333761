/** @jsx jsx */
import {jsx, css} from '@emotion/core'

const container = css`
	background: rgba(77, 93, 114, 0.08);
	border-radius: 2px;
	padding: 0.57rem;
`

const icon = css`
	width: 1.14rem;
	height: auto;
`

function IconInSquare({
	src,
	alt,
	className,
}: {
	src: string
	alt?: string
	className?: string
}) {
	return (
		<div className={className} css={container}>
			<img css={icon} src={src} alt={alt} />
		</div>
	)
}

export default IconInSquare
