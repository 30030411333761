/** @jsx jsx */
import {jsx, css} from '@emotion/core'

const container = css`
	padding: 1.4rem;
`

function Container({
	className,
	children,
}: {
	className?: string
	children?: React.ReactNode
}) {
	return (
		<div className={className} css={container}>
			{children}
		</div>
	)
}

export default Container
